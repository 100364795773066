export const USER_SIGN_IN_REQUEST = "USER_SIGN_IN_REQUEST";
export const USER_SIGN_IN_SUCCESS = "USER_SIGN_IN_SUCCESS";
export const USER_SIGN_IN_FAILURE = "USER_SIGN_IN_FAILURE";

export const USER_SIGN_OUT_REQUEST = "USER_SIGN_OUT_REQUEST";
export const USER_SIGN_OUT_SUCCESS = "USER_SIGN_OUT_SUCCESS";
export const USER_SIGN_OUT_FAILURE = "USER_SIGN_OUT_FAILURE";

export const GET_DOSSIERS_REQUEST = "GET_DOSSIERS_REQUEST";
export const GET_DOSSIERS_SUCCESS = "GET_DOSSIERS_SUCCESS";
export const GET_DOSSIERS_FAILURE = "GET_DOSSIERS_FAILURE";

export const GET_DOSSIERS_BY_PRODUCT_REQUEST =
  "GET_DOSSIERS_BY_PRODUCT_REQUEST";
export const GET_DOSSIERS_BY_PRODUCT_SUCCESS =
  "GET_DOSSIERS_BY_PRODUCT_SUCCESS";
export const GET_DOSSIERS_BY_PRODUCT_FAILURE =
  "GET_DOSSIERS_BY_PRODUCT_FAILURE";

export const GET_MODULES_BY_PRODUCT_REQUEST = "GET_MODULES_BY_PRODUCT_REQUEST";
export const GET_MODULES_BY_PRODUCT_SUCCESS = "GET_MODULES_BY_PRODUCT_SUCCESS";
export const GET_MODULES_BY_PRODUCT_FAILURE = "GET_MODULES_BY_PRODUCT_FAILURE";

export const GET_VERSIONS_BY_PRODUCT_REQUEST =
  "GET_VERSIONS_BY_PRODUCT_REQUEST";
export const GET_VERSIONS_BY_PRODUCT_SUCCESS =
  "GET_VERSIONS_BY_PRODUCT_SUCCESS";
export const GET_VERSIONS_BY_PRODUCT_FAILURE =
  "GET_VERSIONS_BY_PRODUCT_FAILURE";

export const GET_PROCHAINE_VERSIONS_REQUEST = "GET_PROCHAINE_VERSIONS_REQUEST";
export const GET_PROCHAINE_VERSIONS_SUCCESS = "GET_PROCHAINE_VERSIONS_SUCCESS";
export const GET_PROCHAINE_VERSIONS_FAILURE = "GET_PROCHAINE_VERSIONS_FAILURE";

export const ADD_DOSSIERS_REQUEST = "ADD_DOSSIERS_REQUEST";
export const ADD_DOSSIERS_SUCCESS = "ADD_DOSSIERS_SUCCESS";
export const ADD_DOSSIERS_FAILURE = "ADD_DOSSIERS_FAILURE";

export const SET_FILTERS = "SET_FILTERS";

export const GET_CLIENTS_REQUEST = "GET_CLIENTS_REQUEST";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";

export const GET_PRODUITS_REQUEST = "GET_PRODUITS_REQUEST";
export const GET_PRODUITS_SUCCESS = "GET_PRODUITS_SUCCESS";
export const GET_PRODUITS_FAILURE = "GET_PRODUITS_FAILURE";
