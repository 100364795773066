import Connexion from "./Connexion";
import Deconnexion from "./Deconnexion";
import Parametres from "./Parametres";

import Home from "./Home";

const screens = [
  {
    component: Home,
    key: "home",
    path: "/",
    id: "home",
    title: "Accueil",
    description: "Tableau de bord de l'application.",
    exact: true,
    privateRoute: true,
    transitionAnimation: true,
  },
  {
    component: Parametres,
    key: "parametres",
    path: "/parametres",
    id: "parametres",
    title: "Parametres",
    description: "Ecran de saisie des paramètres de l'application.",
    exact: true,
    privateRoute: true,
    transitionAnimation: true,
  },
  // {
  //   component: Connexion,
  //   key: "home",
  //   path: "/index.html",
  //   id: "home",
  //   title: "Connexion",
  //   description:
  //     "Ecran de saisie des identifiants pour accéder à l'application",
  //   exact: true,
  //   privateRoute: false,
  //   transitionAnimation: true,
  // },
  {
    component: Connexion,
    key: "connexion",
    path: "/connexion",
    id: "home",
    title: "Connexion",
    description:
      "Ecran de saisie des identifiants pour accéder à l'application.",
    exact: true,
    privateRoute: false,
    transitionAnimation: true,
  },
  {
    component: Deconnexion,
    key: "deconnexion",
    path: "/deconnexion",
    id: "home",
    title: "Déconnexion",
    description: `Ecran de confirmation de fermeture de session.`,
    exact: true,
    privateRoute: false,
    transitionAnimation: true,
  },
];

export default screens;
