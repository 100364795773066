import { all, takeLatest } from "redux-saga/effects";
import { signUserIn, signUserOut } from "./sagas/user";
import {
  getDossiers,
  addDossiers,
  getDossiersByProduct
} from "./sagas/dossier";
import { getModulesByProduct } from "./sagas/module";
import { getClients } from "./sagas/client";
import { getProduits } from "./sagas/produit";
import { getVersionsByProduct, getProchaineVersions } from "./sagas/version";

import {
  USER_SIGN_IN_REQUEST,
  USER_SIGN_OUT_REQUEST,
  GET_DOSSIERS_REQUEST,
  GET_CLIENTS_REQUEST,
  GET_PRODUITS_REQUEST,
  ADD_DOSSIERS_REQUEST,
  GET_DOSSIERS_BY_PRODUCT_REQUEST,
  GET_MODULES_BY_PRODUCT_REQUEST,
  GET_VERSIONS_BY_PRODUCT_REQUEST,
  GET_PROCHAINE_VERSIONS_REQUEST
} from "./constants/ActionTypes";

export default function* rootSaga() {
  yield all([
    takeLatest(USER_SIGN_IN_REQUEST, signUserIn),
    takeLatest(USER_SIGN_OUT_REQUEST, signUserOut),
    takeLatest(GET_DOSSIERS_REQUEST, getDossiers),
    takeLatest(GET_CLIENTS_REQUEST, getClients),
    takeLatest(GET_PRODUITS_REQUEST, getProduits),
    takeLatest(ADD_DOSSIERS_REQUEST, addDossiers),
    takeLatest(GET_DOSSIERS_BY_PRODUCT_REQUEST, getDossiersByProduct),
    takeLatest(GET_MODULES_BY_PRODUCT_REQUEST, getModulesByProduct),
    takeLatest(GET_VERSIONS_BY_PRODUCT_REQUEST, getVersionsByProduct),
    takeLatest(GET_PROCHAINE_VERSIONS_REQUEST, getProchaineVersions)
  ]);
}
