import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider } from "styled-components";

import AnimatedRoutes from "./routes/Animated";
import theme from "./ui/theme";
import GlobalStyle from "./ui/assets/css/global-css";
import "semantic-ui-css/semantic.min.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { history } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <>
          <Router>
            <AnimatedRoutes history={history} animatedTransitions />
          </Router>
          <GlobalStyle />
        </>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  iPKUtilisateur: state.user.data.iPKUtilisateur
});

export default connect(mapStateToProps)(App);
