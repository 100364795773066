import {
  GET_DOSSIERS_SUCCESS,
  GET_DOSSIERS_FAILURE,
  ADD_DOSSIERS_SUCCESS,
  ADD_DOSSIERS_FAILURE,
  GET_DOSSIERS_BY_PRODUCT_SUCCESS,
  GET_DOSSIERS_BY_PRODUCT_FAILURE,
  GET_MODULES_BY_PRODUCT_SUCCESS,
  GET_MODULES_BY_PRODUCT_FAILURE,
  GET_VERSIONS_BY_PRODUCT_SUCCESS,
  GET_VERSIONS_BY_PRODUCT_FAILURE,
  GET_PROCHAINE_VERSIONS_SUCCESS,
  GET_PROCHAINE_VERSIONS_FAILURE,
  SET_FILTERS,
} from "../constants/ActionTypes";

const initialconfigState = {
  tDossier: [],
  tOptionDossier: [],
  tProchaineVersion: [],
  filters: {},
  error: null,
};

export default (state = initialconfigState, { type, payload }) => {
  switch (type) {
    case GET_DOSSIERS_SUCCESS: {
      return {
        ...state,
        tDossier: payload.tDossier,
        error: null,
      };
    }
    case GET_DOSSIERS_FAILURE: {
      return { ...state, error: payload };
    }
    case GET_DOSSIERS_BY_PRODUCT_SUCCESS: {
      return {
        ...state,
        tOptionDossier: payload.tDossier,
        error: null,
      };
    }
    case GET_DOSSIERS_BY_PRODUCT_FAILURE: {
      return { ...state, error: payload };
    }
    case GET_MODULES_BY_PRODUCT_SUCCESS: {
      return {
        ...state,
        tOptionModule: payload.tModule,
        error: null,
      };
    }
    case GET_MODULES_BY_PRODUCT_FAILURE: {
      return { ...state, error: payload };
    }
    case GET_VERSIONS_BY_PRODUCT_SUCCESS: {
      return {
        ...state,
        tOptionVersion: payload.tVersion,
        error: null,
      };
    }
    case GET_VERSIONS_BY_PRODUCT_FAILURE: {
      return { ...state, error: payload };
    }
    case GET_PROCHAINE_VERSIONS_SUCCESS: {
      return {
        ...state,
        tProchaineVersion: payload.tVersion.map((v) => {
          return {
            ...v,
            sConcerne: v.sConcerne ? v.sConcerne.substring(0, 25) : "",
          };
        }),
        error: null,
      };
    }
    case GET_PROCHAINE_VERSIONS_FAILURE: {
      return { ...state, error: payload };
    }
    case ADD_DOSSIERS_SUCCESS: {
      return {
        ...state,
        tDossier: [...state.tDossier, ...payload.tDossier],
        error: null,
      };
    }
    case ADD_DOSSIERS_FAILURE: {
      return { ...state, error: payload };
    }
    case SET_FILTERS: {
      return { ...state, filters: payload.params };
    }
    default:
      return state;
  }
};
