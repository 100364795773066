import {
  GET_PRODUITS_SUCCESS,
  GET_PRODUITS_FAILURE
} from "../constants/ActionTypes";

const initialconfigState = {
  tProduit: [],
  error: null
};

export default (state = initialconfigState, { type, payload }) => {
  switch (type) {
    case GET_PRODUITS_SUCCESS: {
      return {
        ...state,
        tProduit: payload.tProduit,
        error: null
      };
    }
    case GET_PRODUITS_FAILURE: {
      return { ...state, error: payload };
    }
    default:
      return state;
  }
};
