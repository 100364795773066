import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";
import { fetchApollo } from "../../graphql/apolloClient";

import {
  GET_PRODUITS_SUCCESS,
  GET_PRODUITS_FAILURE
} from "../constants/ActionTypes";

export function tmp() {}

export function* getProduits({ payload: { params } }) {
  try {
    const GET_PRODUITS = gql`
      {
        getProduits ${params ? `(iFKClient: ${params}) ` : ""} {
          iPKProduit
          sLibelle
        }
      }
    `;
    const response = yield call(fetchApollo, GET_PRODUITS);
    const produits = response.data.getProduits;

    if (produits !== null) {
      yield put({
        type: GET_PRODUITS_SUCCESS,
        payload: { tProduit: produits }
      });
    } else {
      yield put({
        type: GET_PRODUITS_FAILURE,
        payload: "Erreur de chargement des produits"
      });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Erreur de chargement des produits";
        break;
      default:
        message = "Erreur de chargement des produits";
        break;
    }

    yield put({ type: GET_PRODUITS_FAILURE, payload: message });
  } finally {
    /*yield put({
        type: LOADING_DEACTIVATE_SUCCESS,
        payload: { target: "config" }
      });*/
  }
}
