import React from "react";
import PropTypes from "prop-types";

import { Icon, Input } from "semantic-ui-react";

import styled from "styled-components";

const Control = styled.div`
  display: inline-flex;
  width: 100%;
`;

const FormLabel = styled.label`
  padding-right: 1em;
  width: 110px !important;
  line-height: 36px;
  font-weight: 700;
  text-align: right;
`;

const FormInput = styled(Input)`
  flex-grow: 1;
`;

const PasswordInput = ({ password, onChange, error }) => {
  return (
    <Control>
      <FormLabel htmlFor="password">Mot de passe&nbsp;:</FormLabel>
      <FormInput
        type="password"
        id="password"
        icon={
          <Icon
            name="eye"
            onMouseDown={() => {
              const pwd = document.getElementById("password");
              pwd.setAttribute("type", "text");
            }}
            onMouseUp={() => {
              const pwd = document.getElementById("password");
              pwd.setAttribute("type", "password");
            }}
            link
          />
        }
        placeholder="Mot de passe"
        autoComplete="password"
        value={password}
        onChange={onChange}
        error={error}
      />
    </Control>
  );
};

PasswordInput.defaultProps = {
  password: "",
  error: ""
};

PasswordInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  password: PropTypes.string,
  error: PropTypes.string
};

export default PasswordInput;
