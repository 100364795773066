import { TweenLite, TweenMax } from "gsap";

const handleComplete = target =>
  TweenLite.set(target, {
    clearProps: "transform"
  });

export const handleScreenEnterAnimation = node => {
  if (!node) return;

  TweenMax.killTweensOf(node);

  TweenLite.set(node, {
    opacity: 0,
    autoAlpha: 0,
    maxWidth: "100%",
    maxHeigh: "100%",
    "z-index": 100
  });

  TweenLite.to(node, 0.5, {
    force3D: true,
    opacity: 1,
    autoAlpha: 1,
    onComplete: handleComplete,
    onCompleteParams: [node]
  });
};

export const handleScreenExitAnimation = node => {
  if (!node) return;

  TweenMax.killTweensOf(node);

  TweenLite.set(node, {
    opacity: 1,
    autoAlpha: 1,
    maxWidth: "100%",
    maxHeigh: "100%",
    overflow: "hidden",
    "z-index": 0
  });

  TweenLite.to(node, 0.5, {
    force3D: true,
    opacity: 0,
    autoAlpha: 0
  });
};

export const handleNotificationsContainerCloseAnimation = node => {
  if (!node) return;

  TweenMax.killTweensOf(node);

  TweenLite.set(node, {
    right: 0
  });

  TweenLite.to(node, 0.35, {
    force3D: true,
    right: "-300px",
    onComplete: handleComplete,
    onCompleteParams: [node]
  });
};

export const handleNotificationsContainerOpenAnimation = node => {
  if (!node) return;

  TweenMax.killTweensOf(node);

  TweenLite.set(node, {
    right: "-300px"
  });

  TweenLite.to(node, 0.35, {
    force3D: true,
    right: 0,
    onComplete: handleComplete,
    onCompleteParams: [node]
  });
};

export const handleFiltersContainerCloseAnimation = node => {
  if (!node) return;

  TweenMax.killTweensOf(node);

  TweenLite.set(node, {
    left: 0
  });

  TweenLite.to(node, 0.35, {
    force3D: true,
    left: "-260px",
    onComplete: handleComplete,
    onCompleteParams: [node]
  });
};

export const handleFiltersContainerOpenAnimation = node => {
  if (!node) return;

  TweenMax.killTweensOf(node);

  TweenLite.set(node, {
    left: "-260px"
  });

  TweenLite.to(node, 0.35, {
    force3D: true,
    left: 0,
    onComplete: handleComplete,
    onCompleteParams: [node]
  });
};
