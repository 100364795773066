// import jwtDecode from "jwt-decode";

import {
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAILURE,
  USER_SIGN_OUT_SUCCESS,
  USER_SIGN_OUT_FAILURE
} from "../constants/ActionTypes";

const initialAuthState = {
  isAuthenticated: false,
  data: {
    iPKUtilisateur: 0,
    iRole: 3,
    sLogin: "Invité"
  },
  error: null
};

export default (state = initialAuthState, { type, payload }) => {
  switch (type) {
    case USER_SIGN_IN_SUCCESS: {
      const data = {
        iPKUtilisateur: payload.iPKUtilisateur,
        iRole: payload.iRole,
        iFKClient: payload.iFKClient,
        sLogin: payload.sLogin
      };
      return { ...state, isAuthenticated: true, data, error: null };
    }
    case USER_SIGN_IN_FAILURE: {
      return { ...initialAuthState, error: payload };
    }

    case USER_SIGN_OUT_SUCCESS: {
      return {
        isAuthenticated: false,
        data: {
          iPKUtilisateur: 0,
          iRole: 3,
          sLogin: "Invité"
        },
        error: null
      };
    }
    case USER_SIGN_OUT_FAILURE: {
      return { ...initialAuthState, error: payload };
    }
    default:
      return state;
  }
};
