import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import styled from "styled-components";
import _ from "lodash";
import { Table, Icon, Button, Modal, Popup } from "semantic-ui-react";

import { addDossiers, setFilters } from "../../redux/actions/dossier";

import { formatDate, getWeek } from "../../utils";

const TextCell = styled.div`
  white-space: pre-wrap;
  max-height: 200px;
  overflow: hidden;
  position: relative;
  line-height: 15px;
`;

const FullTextCell = styled.div`
  white-space: pre-wrap;
  line-height: 15px;
  color: ${(props) => (props.bVigilance ? "red" : "")};
`;

const ShowMoreBtn = styled(Button)`
  height: 35px;
  background: rgb(0, 151, 174) !important;
  color: white !important;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const NbDossier = styled.div`
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: -10px;
`;

class SortableTable extends Component {
  state = {
    column: "sVersion",
    direction: "descending",
    tDossier: [],
  };

  dispatching = false;

  componentDidUpdate(prevProps) {
    const { tDossier } = this.props;
    if (prevProps.tDossier !== tDossier) {
      this.setState({ tDossier });
      this.dispatching = false;
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, direction, tDossier } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        tDossier: _.sortBy(tDossier, [clickedColumn]),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      tDossier: tDossier.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleScroll = (event) => {
    const { dispatch, filters } = this.props;
    const current = event.target.scrollTop;
    const maxHeight = event.target.scrollHeight;

    if (!filters.produit) {
      filters.produit = 2;
    }
    if (current > maxHeight * 0.8 && !this.dispatching) {
      if (!filters.offset) {
        filters.offset = 100;
      } else {
        filters.offset += 100;
      }
      this.dispatching = true;
      dispatch(addDossiers(filters));
      dispatch(setFilters(filters));
    }
  };

  render() {
    const { column, direction, tDossier } = this.state;
    const { user, iTotalNbDossier } = this.props;
    let sVersion;
    return (
      <>
        <NbDossier className="nbDossiers">
          Nb dossiers : {iTotalNbDossier}
          <a
            style={{ position: "relative", right: "75%" }}
            target="_blank"
            rel="noopener noreferrer"
            href="http://isadom.net"
          >
            Aide en ligne
          </a>
        </NbDossier>
        <Table sortable celled striped>
          <Table.Header
            className="no-print"
            style={{ width: "calc(100vw - 40px)" }}
          >
            <Table.Row>
              <Table.HeaderCell
                textAlign="center"
                width={1}
                className={`sortable sorted-${direction}`}
                sorted={column === "sProduit" ? direction : null}
                onClick={this.handleSort("sProduit")}
              >
                Produit
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                width={1}
                sorted={column === "sFKNature" ? direction : null}
                onClick={this.handleSort("sFKNature")}
              >
                Type
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                width={1}
                sorted={column === "sVersion" ? direction : null}
                onClick={this.handleSort("sVersion")}
              >
                Version
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                width={1}
                sorted={column === "sModule" ? direction : null}
                onClick={this.handleSort("sModule")}
              >
                Module
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "sDescription" ? direction : null}
                onClick={this.handleSort("sDescription")}
              >
                Contenu
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                width={1}
                sorted={column === "iPKDossier" ? direction : null}
                onClick={this.handleSort("iPKDossier")}
              >
                Dossier
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                width={1}
                sorted={column === "sNomClient" ? direction : null}
                onClick={this.handleSort("sNomClient")}
              >
                Client
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body
            style={{ height: "calc(100vh - 335px)" }}
            onScroll={this.handleScroll}
          >
            {tDossier.map((dossier) => {
              const isPrevisionnel =
                !dossier.dDeploiement ||
                new Date(dossier.dDeploiement).getTime() > new Date().getTime();

              const oldVersion = sVersion;

              if (
                !dossier.bSpecifique ||
                user.iRole === 2 ||
                user.iFKClient === dossier.iFKClient ||
                user.iFKClient === 99
              ) {
                if (isPrevisionnel) {
                  sVersion = `prévisionnelle : ${dossier.sVersionPrevisionnelle}`;
                } else if (dossier.sVersionPrevisionnelle) {
                  sVersion = dossier.sVersionPrevisionnelle;
                } else {
                  sVersion = dossier.sVersion;
                }
              }

              // eslint-disable-next-line consistent-return
              const createVersionLabel = () => {
                if (dossier.dVersionPreviFin && isPrevisionnel)
                  return ` (déploiement semaine ${getWeek(
                    dossier.dVersionPreviFin
                  )})`;
                if (dossier.dDeploiement)
                  return ` livrée le ${formatDate(dossier.dDeploiement)}`;
              };

              return !dossier.bSpecifique ||
                user.iRole === 2 ||
                user.iFKClient === dossier.iFKClient ||
                user.iFKClient === 99 ? (
                <>
                  {sVersion !== oldVersion ? (
                    <Table.Row
                      style={{
                        position: "relative",
                        backgroundColor: "grey",
                      }}
                      className={dossier.bSpecifique ? "specifique" : ""}
                      key={`version-${dossier.iPKDossier}-${dossier.iFKClient}`}
                    >
                      <Table.Cell rowSpan="7" textAlign="center">
                        <b style={{ color: "white", fontSize: 14 }}>
                          {isPrevisionnel ? (
                            <Icon
                              style={{ color: "blue" }}
                              name="hourglass half"
                            />
                          ) : null}{" "}
                          Version {sVersion}
                        </b>
                        <span style={{ color: "white", fontSize: 12 }}>
                          {createVersionLabel()}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  <Table.Row
                    style={{
                      position: "relative",
                      display: "none",
                    }}
                    className="print"
                    key={`print-${dossier.iPKDossier}-${dossier.iFKClient}`}
                  >
                    <Table.Cell rowSpan="7" textAlign="left">
                      <span style={{ fontSize: 14 }}>
                        <b>{dossier.iPKDossier}</b> - <b>{dossier.sProduit}</b>{" "}
                        - <b>{dossier.sNature}</b> - <b>{dossier.sModule}</b> -{" "}
                        <b>({dossier.sNomClient})</b>
                      </span>
                      <br />
                      <FullTextCell bVigilance={dossier.bVigilance}>
                        {dossier.sDescription}
                      </FullTextCell>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row
                    style={{
                      position: "relative",
                      backgroundColor:
                        dossier.sFKNature === "A"
                          ? "rgba(140, 20, 20, 0.12)"
                          : dossier.sFKNature === "M"
                          ? "rgba(20, 64, 140, 0.12)"
                          : dossier.sFKNature === "I"
                          ? "rgba(109, 8, 117, 0.16)"
                          : "inerit",
                      color: dossier.bVigilance && "red",
                    }}
                    className="no-print"
                    key={`row-${dossier.iPKDossier}-${dossier.iFKClient}`}
                  >
                    <Table.Cell
                      style={{
                        borderLeft: dossier.bSpecifique ? "solid 5px red" : "",
                      }}
                      width={1}
                      textAlign="center"
                    >
                      {dossier.sProduit}
                    </Table.Cell>
                    <Table.Cell width={1} textAlign="center">
                      {dossier.sFKNature}
                    </Table.Cell>
                    <Table.Cell width={1} textAlign="center">
                      {isPrevisionnel ? (
                        <Popup
                          content="Version prévisionnelle"
                          trigger={
                            <p>
                              <Icon
                                style={{ color: "blue" }}
                                name="hourglass half"
                              />
                              {dossier.sVersionPrevisionnelle}
                            </p>
                          }
                        />
                      ) : (
                        sVersion
                      )}
                    </Table.Cell>
                    <Table.Cell width={1} textAlign="center">
                      {dossier.sModule === "Prestations/Prescriptions" ||
                      dossier.sModule === "Ordonnancement/Planification" ||
                      dossier.sModule === "SuiviDomicile/Intégration visites" ||
                      dossier.sModule === "SuiviDomicile/Préparation bases" ||
                      dossier.sModule === "Ordonnancement/Tournées" ||
                      dossier.sModule === "Sécurités/Autorisations"
                        ? dossier.sModule.replace("/", "\n")
                        : dossier.sModule}
                    </Table.Cell>
                    <Table.Cell>
                      <TextCell>
                        {dossier.bSpecifique && (
                          <>
                            <b style={{ color: "red" }}>
                              *** Dossier spécifique ***
                            </b>
                            <br />
                          </>
                        )}
                        {dossier.bVigilance && (
                          <>
                            <p style={{ color: "red" }}>
                              *** <Icon name="eye" style={{ color: "red" }} />{" "}
                              Vigilance client ***
                            </p>
                          </>
                        )}
                        {dossier.sDescription}
                        {dossier.sDescription &&
                          dossier.sDescription.split(/\r\n|\r|\n/).length >
                            13 && (
                            <>
                              <br />
                              <Modal
                                trigger={
                                  <ShowMoreBtn>Voir la suite</ShowMoreBtn>
                                }
                                closeIcon
                              >
                                <Modal.Content>
                                  <Modal.Description>
                                    <FullTextCell>
                                      {dossier.sDescription}
                                    </FullTextCell>
                                  </Modal.Description>
                                </Modal.Content>
                              </Modal>
                            </>
                          )}
                      </TextCell>
                    </Table.Cell>
                    <Table.Cell width={1} textAlign="center">
                      <a
                        data-tooltip="Ouvre le dossier dans l'Assistance"
                        href={`assistancelink://${dossier.iPKDossier}`}
                      >
                        {dossier.iPKDossier}
                      </a>
                    </Table.Cell>
                    <Table.Cell
                      style={{ color: "black" }}
                      width={1}
                      textAlign="center"
                    >
                      {dossier.sNomClient}
                    </Table.Cell>
                  </Table.Row>
                </>
              ) : null;
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

SortableTable.defaultProps = {
  tDossier: [],
  filters: {},
};

SortableTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tDossier: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  iTotalNbDossier: PropTypes.number.isRequired,
  filters: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  tDossier: state.dossier.tDossier,
  iTotalNbDossier: state.dossier.tOptionDossier.length,
  user: state.user.data,
  filters: state.dossier.filters,
});

export default connect(mapStateToProps)(SortableTable);
