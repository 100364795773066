import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import { Container, Image, Menu, Icon } from "semantic-ui-react";
import styled from "styled-components";

import LoadingBar from "react-redux-loading-bar";

import { signUserOut } from "../../redux/actions/user";

import logoADS from "../../ui/assets/img/logoADS.png";
import avatar from "../../ui/assets/img/avatar.svg";

const fixedMenuStyle = {
  backgroundColor: "#fff",
  border: "1px solid #ddd",
  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
};

const Avatar = styled.img`
  float: left;
  display: block;
  margin: 5px 20px 5px 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid rgba(34, 36, 38, 0.1);
`;

const BtnUserSignOut = styled.button`
  width: 40px;
  height: 40px;
  color: ${(props) => props.theme.colors.blueDark};
  background: ${(props) => props.theme.colors.white};
  border: 0;
  cursor: pointer;
  text-align: center;
  transition: background ease-in-out 0.25s;

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.blueDark};
  }

  & i {
    margin: 0 !important;
  }
`;

const BtnUserSignIn = styled.a`
  color: ${(props) => props.theme.colors.blueDark};
  background: ${(props) => props.theme.colors.white};
  font-weight: bold;
  border: 0;
  display: block;
  cursor: pointer;
  text-align: center;
  line-height: 18px;
  padding: 10px;
  transition: background ease-in-out 0.25s;

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.blueDark};
  }

  & i {
    margin: 0 !important;
  }
`;

const UserName = styled.div`
  float: left;
  margin: 5px 20px 5px 0;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.textBlack};
`;

const Title = styled.h1`
  font-size: 18px;
`;

const MenuContainer = styled(Container)`
  padding: 0 20px;
  justify-content: space-between;
  height: 40px;
  width: 100%;
`;

const NavItemLink = styled(NavLink)`
  display: block;
  padding: 0 10px;
  padding-top: 5px;
  height: 40px;
  line-height: 40px;
  font-family: "Futura";
  text-transform: uppercase;
  text-decoration: none;
  color: ${(props) => props.theme.colors.blueDark};
  background: ${(props) => props.theme.colors.white};
  transition: background ease-in-out 0.25s;

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.blueDark};
  }

  &.active {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.blueDark};
  }
`;

const NavItemExternalLink = styled.a`
  display: block;
  padding: 0 10px;
  padding-top: 5px;
  height: 40px;
  line-height: 40px;
  font-family: "Futura";
  text-transform: uppercase;
  text-decoration: none;
  color: ${(props) => props.theme.colors.blueDark};
  background: ${(props) => props.theme.colors.white};
  transition: background ease-in-out 0.25s;

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.blueDark};
  }

  &.active {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.blueDark};
    border-right: 1px solid ${(props) => props.theme.colors.blueDark};
  }
`;

const CustomItem = styled(Menu.Item)`
  margin-right: 10px !important;
  width: 51px !important;
`;

class AdsMenu extends Component {
  signUserOut = () => {
    const { dispatch } = this.props;
    window.location.hash = "";
    dispatch(signUserOut());
  };

  render() {
    const { user } = this.props;
    return (
      <Menu
        className="no-print"
        borderless
        fixed="top"
        fluid
        widths={3}
        style={fixedMenuStyle}
      >
        <LoadingBar style={{ backgroundColor: "#0097ae", zIndex: 2 }} />
        <MenuContainer fluid>
          <Menu.Item style={{ justifyContent: "flex-start" }}>
            <NavItemLink to="/" style={{ background: "#fff" }}>
              <Image size="tiny" src={logoADS} />
            </NavItemLink>
          </Menu.Item>
          <Menu.Item>
            <Title>Contenu versions</Title>
          </Menu.Item>
          <Menu.Item style={{ justifyContent: "flex-end" }}>
            {user.isAuthenticated ? (
              <>
                <Menu.Item
                  style={{ float: "right", width: 51, marginRight: 10 }}
                >
                  <NavItemExternalLink
                    href="http://isadom.net"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-tooltip="Aide en ligne d'is@dom"
                    data-position="bottom left"
                  >
                    <Icon name="help" size="big" />
                  </NavItemExternalLink>
                </Menu.Item>
                <CustomItem>
                  <NavItemLink to="/parametres">
                    <Icon name="cog" size="big" />
                  </NavItemLink>
                </CustomItem>
                <div style={{ width: 10 }}></div>
                <Avatar src={avatar} />
                <UserName>{user.data.sLogin}.</UserName>
                <BtnUserSignOut onClick={this.signUserOut}>
                  <Icon name="power off" size="large" />
                </BtnUserSignOut>
              </>
            ) : (
              <BtnUserSignIn href="/connexion">Connexion</BtnUserSignIn>
            )}
          </Menu.Item>
        </MenuContainer>
      </Menu>
    );
  }
}

AdsMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AdsMenu);
