import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
  credentials: "same-origin"
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.jwtToken;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const created = {};

export const mutateApollo = (query, noCache = true) => {
  if (
    !created.planning ||
    Date.now() - created.planning > process.env.REACT_APP_APOLLO_CACHE_TTL
  ) {
    created.planning = Date.now();
    client.cache.reset();
  }

  return new Promise((resolve, reject) =>
    client
      .mutate({
        mutation: query,
        fetchPolicy: noCache === true ? "no-cache" : "cache-first"
      })
      .then(response => resolve(response))
      .catch(error => reject(error))
  );
};

export const fetchApollo = (query, noCache = true) => {
  if (
    !created.planning ||
    Date.now() - created.planning > process.env.REACT_APP_APOLLO_CACHE_TTL
  ) {
    created.planning = Date.now();
    client.cache.reset();
  }

  return new Promise((resolve, reject) =>
    client
      .query({
        query,
        fetchPolicy: noCache === true ? "no-cache" : "cache-first"
      })
      .then(response => resolve(response))
      .catch(error => reject(error))
  );
};
