import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";
import { fetchApollo } from "../../graphql/apolloClient";

import {
  GET_MODULES_BY_PRODUCT_SUCCESS,
  GET_MODULES_BY_PRODUCT_FAILURE
} from "../constants/ActionTypes";

export function tmp() {}

export function* getModulesByProduct({ payload: { params } }) {
  try {
    const GET_MODULES = gql`
      {
        getModules (iFKProduit: ${params}) {
          iPKModule
          sLibelle
        }
      }
    `;
    const response = yield call(fetchApollo, GET_MODULES);
    const modules = response.data.getModules;

    if (modules !== null) {
      yield put({
        type: GET_MODULES_BY_PRODUCT_SUCCESS,
        payload: { tModule: modules }
      });
    } else {
      yield put({
        type: GET_MODULES_BY_PRODUCT_FAILURE,
        payload: "Erreur de chargement des modules"
      });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Erreur de chargement des modules";
        break;
      default:
        message = "Erreur de chargement des modules";
        break;
    }

    yield put({ type: GET_MODULES_BY_PRODUCT_FAILURE, payload: message });
  } finally {
    /*yield put({
        type: LOADING_DEACTIVATE_SUCCESS,
        payload: { target: "config" }
      });*/
  }
}
