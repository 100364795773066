import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { saveAs } from "file-saver";

import { Icon, Card, Container } from "semantic-ui-react";

import AdsMenu from "../../components/Core/Menu";

const Actionbutton = styled.button`
  margin: 0 0 5px 5px;
  padding: 10px;
  padding-left: 35px;
  height: 40px;
  width: 250px;
  text-align: center;
  color: ${props => (props.selected ? props.theme.colors.white : "inherit")};
  background: ${props =>
    getBackgroundColor(props.theme.colors, props.selected, props.system)};
  border: 1px solid ${props => props.theme.colors.borderLight};
  outline: none;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  display: inline-flex;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blueDark};
  }
`;

const getBackgroundColor = (colors, selected, system) => {
  if (selected) {
    return colors.blueAGIR;
  }

  if (system) {
    return colors.backgroundLight;
  }

  return colors.white;
};

class Parametres extends Component {
  downloadReg = () => {
    const { user } = this.props;
    const pathCustomer = {
      ADS: "\\\\\\\\sql2016\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe",
      SANTELYS:
        "\\\\\\\\SRVISADOM\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe",
      ASTEN: "\\\\\\\\I12MES001\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe",
      ARAIR: "\\\\\\\\ARAIRISADOM\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe",
      APARD: "\\\\\\\\ISADOM-MTP\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe",
      ALLP: "\\\\\\\\ALLP-ERP\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe",
      ALISEO: "\\\\\\\\VMISADOM\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe",
      ALAIR: "\\\\\\\\ALAIRVRT\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe",
      AIRRA: "\\\\\\\\SRVISADOM\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe",
      AIRCAEN: "\\\\\\\\SQL\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe",
      AGIRADOM:
        "\\\\\\\\AGIR-ISA1\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe",
      ADEP: "\\\\\\\\ADEPISADOM\\\\ADSISADOM\\\\ASSISTANCE\\\\Assistance.exe"
    };
    const content = `Windows Registry Editor Version 5.00

[HKEY_CLASSES_ROOT\\assistancelink]
"url protocol"=""

[HKEY_CLASSES_ROOT\\assistancelink\\shell]

[HKEY_CLASSES_ROOT\\assistancelink\\shell\\open]

[HKEY_CLASSES_ROOT\\assistancelink\\shell\\open\\command]
@="\\"${pathCustomer[user.sLogin]}\\"\\"%1\\""`;
    // any kind of extension (.txt,.cpp,.cs,.bat)
    const filename = "AssistanceLink.reg";

    const blob = new Blob([content], {
      type: "text/plain;charset=utf-8"
    });

    saveAs(blob, filename);
  };

  render() {
    return (
      <>
        <AdsMenu />
        <Container
          id="main-container"
          style={{ width: "95%", margin: "0 auto", marginTop: 45 }}
          fluid
        >
          <br />
          <Card>
            <Card.Content>
              <Card.Header>Télécharger le fichier REG</Card.Header>
            </Card.Content>
            <Card.Content>
              <Actionbutton
                data-tooltip="Fichier à exécuter sur votre poste, il permet d'ouvrir l'Assistance depuis le navigateur web"
                data-position="bottom left"
                onClick={this.downloadReg}
              >
                <Icon name="download" /> Télécharger le fichier reg
              </Actionbutton>
            </Card.Content>
          </Card>
        </Container>
      </>
    );
  }
}

Parametres.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

export default connect(mapStateToProps)(Parametres);
