const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  colors: {
    blue: "rgb(57, 178, 198)",
    orange: "rgb(248, 179, 52)",
    purple: "rgb(161, 13, 89)",
    purpleBackgroundLighter: "rgba(161, 13, 89, 0.1)",
    purpleBackgroundLight: "rgba(161, 13, 89, 0.25)",
    purpleDark: "rgb(141, 0, 69)",
    blueDark: "rgb(0, 151, 174)",
    greenLight: "rgb(223, 219, 0)",
    greenDark: "rgb(62, 167, 28)",
    yellow: "rgb(252, 234, 13)",
    white: "rgb(255, 255, 255)",
    grey: "rgb(214, 215, 216)",
    textBlack: "rgb(88, 88, 88)",

    inputBorderLight: "rgb(222, 222, 222)",
    inputBorderDark: "rgb(178, 179, 179)",

    borderLight: "rgba(34,36,38,.15)",

    tableRowEven: "rgba(57, 178, 198, 0.1)",
    tableRowEvenHover: "rgba(57, 178, 198, 0.25)",
    tableRowSelected: "rgba(57, 178, 198, 0.4)",
    tableRowUneven: "transparent",

    backgroundLight: "rgb(241, 242, 246)",
    backgroundLighter: "rgb(251, 252, 255)"
  }
};

export default theme;
