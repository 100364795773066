import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";
import jwt from "jsonwebtoken";

import { setAuthorizationToken } from "../../utils";
import { fetchApollo } from "../../graphql/apolloClient";

import {
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAILURE,
  USER_SIGN_OUT_SUCCESS,
  USER_SIGN_OUT_FAILURE
} from "../constants/ActionTypes";

export function* signUserIn({ payload: { sLogin, sPassword } }) {
  if (!navigator.onLine) {
    // console.log("TODO: USER_SIGN_IN_REQUEST Offline");
  } else {
    try {
      const AUTORISE = gql`{
        autoriseUtilisateur(sLogin: "${sLogin}", sPassword: "${sPassword}") {
          sLogin
          iFKClient
          iRole
        }
      }`;
      const response = yield call(fetchApollo, AUTORISE);
      const user = response.data.autoriseUtilisateur;

      if (user !== null) {
        const token = jwt.sign(
          { ...user, sPassword },
          process.env.REACT_APP_JWT_SECRET,
          {
            expiresIn: process.env.REACT_APP_JWT_EXPIRES_IN
          }
        );

        setAuthorizationToken(token);
        localStorage.setItem("jwtToken", JSON.stringify(token));

        yield put({ type: USER_SIGN_IN_SUCCESS, payload: user });
      } else {
        localStorage.removeItem("jwtToken");

        yield put({
          type: USER_SIGN_IN_FAILURE,
          payload: "Identifiants incorrects"
        });
      }
    } catch (error) {
      let { message } = error;

      switch (error.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 404:
          message = "Page not found";
          break;
        case 401:
          message = "Identifiants incorrects";
          break;
        default:
          break;
      }

      localStorage.removeItem("jwtToken");
      yield put({ type: USER_SIGN_IN_FAILURE, payload: message });
    }
  }
}
export function* signUserOut() {
  // client.resetStore();

  if (localStorage.getItem("jwtToken")) {
    localStorage.removeItem("jwtToken");
    yield put({
      type: USER_SIGN_OUT_SUCCESS,
      payload: {}
    });
  } else {
    yield put({
      type: USER_SIGN_OUT_FAILURE,
      payload: {}
    });
  }
}
