import { combineReducers } from "redux";

import { loadingBarReducer } from "react-redux-loading-bar";

import user from "./reducers/user";
import dossier from "./reducers/dossier";
import client from "./reducers/client";
import produit from "./reducers/produit";

const appReducer = combineReducers({
  user,
  dossier,
  client,
  produit,
  loadingBar: loadingBarReducer
});

export default (state, action) => {
  return appReducer(state, action);
};
