import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Container } from "semantic-ui-react";

import AdsMenu from "../../components/Core/Menu";
import SortableTable from "../../components/Core/SortableTable";
import FiltersVersion from "../../components/Home/FiltersVersion";

import {
  getDossiers,
  getDossiersByProduct,
  getVersionsByProduct,
} from "../../redux/actions/dossier";
import { getClients } from "../../redux/actions/client";
import { getProduits } from "../../redux/actions/produit";

class Home extends Component {
  componentDidMount() {
    const { dispatch, user } = this.props;
    if (user.isAuthenticated) {
      dispatch(getDossiers({ type: ["M", "A"], produit: 2, user: user.data }));
    } else {
      dispatch(getDossiers({ type: ["M", "A"], produit: 2 }));
    }
    dispatch(getClients());
    dispatch(getProduits());
    dispatch(getVersionsByProduct(2));
    dispatch(
      getDossiersByProduct({
        produit: 2,
        ...{ limit: false },
      })
    );
  }

  render() {
    const { history } = this.props;
    return (
      <>
        <AdsMenu />
        <Container id="main-container" style={{ padding: "45px 0 0" }} fluid>
          <br />
          <FiltersVersion history={history} />
          <div style={{ padding: "0 15px" }}>
            <SortableTable />
          </div>
        </Container>
      </>
    );
  }
}

Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  tDossier: state.tDossier,
  user: state.user,
});

export default connect(mapStateToProps)(Home);
