import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { fetchApollo } from "../../graphql/apolloClient";

import {
  GET_DOSSIERS_SUCCESS,
  GET_DOSSIERS_FAILURE,
  ADD_DOSSIERS_SUCCESS,
  ADD_DOSSIERS_FAILURE,
  GET_DOSSIERS_BY_PRODUCT_SUCCESS,
  GET_DOSSIERS_BY_PRODUCT_FAILURE,
} from "../constants/ActionTypes";

export function* getDossiers({ payload: { params } }) {
  try {
    yield put(showLoading());
    const GET_DOSSIERS = gql`
      {
        getDossiers ${
          params ? `(sParams: ${JSON.stringify(JSON.stringify(params))}) ` : ""
        } {
          iPKDossier
          iFKClient
          sNomClient
          sProduit
          sFKNature
          sNature
          sModule
          sVersion
          sVersionPrevisionnelle
          dVersionPreviDebut
          dVersionPreviFin
          sDescription
          bADeployer
          bSpecifique
          bVigilance
          dDeploiement
        }
      }
    `;

    const response = yield call(fetchApollo, GET_DOSSIERS);
    const dossiers = response.data.getDossiers;

    if (dossiers !== null) {
      yield put({
        type: GET_DOSSIERS_SUCCESS,
        payload: { tDossier: dossiers },
      });
      yield put(hideLoading());
    } else {
      yield put({
        type: GET_DOSSIERS_FAILURE,
        payload: "Erreur de chargement des dossiers",
      });
      yield put(hideLoading());
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Erreur de chargement des dossiers";
        break;
      default:
        message = "Erreur de chargement des dossiers";
        break;
    }

    yield put({ type: GET_DOSSIERS_FAILURE, payload: message });
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

export function* getDossiersByProduct({ payload: { params } }) {
  try {
    const GET_DOSSIERS = gql`
      {
        getDossiers (sParams: ${JSON.stringify(JSON.stringify(params))}) {
          iPKDossier,
          sConcerne
        }
      }
    `;
    const response = yield call(fetchApollo, GET_DOSSIERS);
    const dossiers = response.data.getDossiers;

    if (dossiers !== null) {
      yield put({
        type: GET_DOSSIERS_BY_PRODUCT_SUCCESS,
        payload: { tDossier: dossiers },
      });
    } else {
      yield put({
        type: GET_DOSSIERS_BY_PRODUCT_FAILURE,
        payload: "Erreur de chargement des dossiers",
      });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Erreur de chargement des dossiers";
        break;
      default:
        message = "Erreur de chargement des dossiers";
        break;
    }

    yield put({ type: GET_DOSSIERS_BY_PRODUCT_FAILURE, payload: message });
  } finally {
    /* yield put({
        type: LOADING_DEACTIVATE_SUCCESS,
        payload: { target: "config" }
      }); */
  }
}

export function* addDossiers({ payload: { params } }) {
  try {
    const GET_DOSSIERS = gql`
      {
        getDossiers ${
          params ? `(sParams: ${JSON.stringify(JSON.stringify(params))}) ` : ""
        } {
          iPKDossier
          iFKClient
          sNomClient
          sProduit
          sFKNature
          sNature
          sModule
          sVersion
          sVersionPrevisionnelle
          dVersionPreviDebut
          dVersionPreviFin
          sDescription
          bADeployer
          bSpecifique
          bVigilance
          dDeploiement
        }
      }
    `;
    const response = yield call(fetchApollo, GET_DOSSIERS);
    const dossiers = response.data.getDossiers;

    if (dossiers !== null) {
      yield put({
        type: ADD_DOSSIERS_SUCCESS,
        payload: { tDossier: dossiers },
      });
    } else {
      yield put({
        type: ADD_DOSSIERS_FAILURE,
        payload: "Erreur de chargement des dossiers",
      });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Erreur de chargement des dossiers";
        break;
      default:
        message = "Erreur de chargement des dossiers";
        break;
    }

    yield put({ type: ADD_DOSSIERS_FAILURE, payload: message });
  } finally {
    /* yield put({
        type: LOADING_DEACTIVATE_SUCCESS,
        payload: { target: "config" }
      }); */
  }
}
