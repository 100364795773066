import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";
import { fetchApollo } from "../../graphql/apolloClient";

import {
  GET_VERSIONS_BY_PRODUCT_SUCCESS,
  GET_VERSIONS_BY_PRODUCT_FAILURE,
  GET_PROCHAINE_VERSIONS_SUCCESS,
  GET_PROCHAINE_VERSIONS_FAILURE
} from "../constants/ActionTypes";

export function* getVersionsByProduct({ payload: { params } }) {
  try {
    const GET_VERSIONS = gql`
      {
        getVersions (iFKProduit: ${params}) {
          sVersionPrevisionnelle
          sVersion
          dDeploiement
        }
      }
    `;
    const response = yield call(fetchApollo, GET_VERSIONS);
    const versions = response.data.getVersions;

    if (versions !== null) {
      yield put({
        type: GET_VERSIONS_BY_PRODUCT_SUCCESS,
        payload: { tVersion: versions }
      });
    } else {
      yield put({
        type: GET_VERSIONS_BY_PRODUCT_FAILURE,
        payload: "Erreur de chargement des versions"
      });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Erreur de chargement des versions";
        break;
      default:
        message = "Erreur de chargement des versions";
        break;
    }

    yield put({ type: GET_VERSIONS_BY_PRODUCT_FAILURE, payload: message });
  } finally {
    /*yield put({
        type: LOADING_DEACTIVATE_SUCCESS,
        payload: { target: "config" }
      });*/
  }
}

export function* getProchaineVersions({ payload: { iFKClient } }) {
  try {
    const GET_VERSIONS = gql`
      {
        getProchaineVersions(iFKClient: ${iFKClient}) {
          sVersionPrevisionnelle
          sProduit
        }
      }
    `;
    const response = yield call(fetchApollo, GET_VERSIONS);
    const versions = response.data.getProchaineVersions;

    if (versions !== null) {
      yield put({
        type: GET_PROCHAINE_VERSIONS_SUCCESS,
        payload: { tVersion: versions }
      });
    } else {
      yield put({
        type: GET_PROCHAINE_VERSIONS_FAILURE,
        payload: "Erreur de chargement des versions"
      });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Erreur de chargement des versions";
        break;
      default:
        message = "Erreur de chargement des versions";
        break;
    }

    yield put({ type: GET_PROCHAINE_VERSIONS_FAILURE, payload: message });
  } finally {
    yield put({
      type: GET_PROCHAINE_VERSIONS_FAILURE,
      payload: "Erreur de chargement des versions"
    });
  }
}
