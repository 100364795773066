import {
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE
} from "../constants/ActionTypes";

const initialconfigState = {
  tClient: [],
  error: null
};

export default (state = initialconfigState, { type, payload }) => {
  switch (type) {
    case GET_CLIENTS_SUCCESS: {
      return {
        ...state,
        tClient: payload.tClient,
        error: null
      };
    }
    case GET_CLIENTS_FAILURE: {
      return { ...state, error: payload };
    }
    default:
      return state;
  }
};
