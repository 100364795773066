import React, { Component } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { Container, Grid, Segment, Form, Input } from "semantic-ui-react";

import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { signUserIn } from "../../redux/actions/user";

import PasswordInput from "../../components/Core/Input/PasswordInput";
import logoSrc from "../../assets/img/ads-logo.png";

const Screen = styled.div`
  padding-top: 40px;
  height: 100vh;
  width: 100%;
  background: ${props => props.theme.colors.backgroundLight};
`;

const WelcomeText = styled.div`
  padding: 2rem 0;
`;

const Control = styled.div`
  display: inline-flex;
  width: 100%;
`;

const ConnexionFormLabel = styled.label`
  padding-right: 1em;
  width: 110px !important;
  line-height: 36px;
  font-weight: 700;
  text-align: right;
`;

const ConnexionFormInput = styled(Input)`
  flex-grow: 1;
`;

const ErrorNotice = styled.div`
  padding: 10px 0 0 110px;
  font-family: "Futura" !important;
  color: ${props => props.theme.colors.purple};
`;

const ConnexionFormSubmit = styled.button`
  position: relative;
  left: 110px;
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  width: 250px;
  max-width: 100%;
`;

const TitleApp = styled.b`
  font-size: 18px;
`;

class Connexion extends Component {
  state = {
    login: "",
    password: "",
    errors: {}
  };

  onChange = evt => {
    const el = evt.target;
    const { value } = evt.target;
    const { errors } = this.state;
    const newErrors = { ...errors };

    if (errors[el.id]) {
      delete newErrors[el.id];
    }

    this.setState({ [el.id]: value, errors: newErrors });
  };

  checkForm = evt => {
    evt.preventDefault();

    const { login, password } = this.state;
    const errors = {};
    const { dispatch } = this.props;

    if (login.length === 0) {
      errors.login = "Veuillez saisir votre identifiant.";
    }

    if (password.length === 0) {
      errors.password = "Veuillez saisir votre mot de passe.";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors: { ...errors } });
    } else {
      dispatch(signUserIn(login, password));
    }
  };

  render() {
    const { isAuthenticated, signInError } = this.props;
    const { login, password, errors } = this.state;

    if (isAuthenticated) {
      return <Redirect to={`/${window.location.hash}`} />;
    }

    return (
      <Screen>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={6}>
                <Segment padded>
                  <Logo src={logoSrc} alt="" />
                  <WelcomeText>
                    Bienvenue sur l&apos;outil de{" "}
                    <TitleApp>suivi de versions</TitleApp> d&apos;ADS.
                    <br />
                    Pour commencer, veuillez vous connecter avec vos
                    identifiants.
                  </WelcomeText>

                  <Form onSubmit={this.checkForm}>
                    <Form.Field inline>
                      <Control>
                        <ConnexionFormLabel htmlFor="login">
                          Identifiant&nbsp;:
                        </ConnexionFormLabel>
                        <ConnexionFormInput
                          id="login"
                          placeholder="Identifiant"
                          autoComplete="login"
                          value={login}
                          onChange={this.onChange}
                          error={errors.login}
                        />
                      </Control>
                      {errors.login && (
                        <ErrorNotice>{errors.login}</ErrorNotice>
                      )}
                    </Form.Field>
                    <Form.Field inline>
                      <PasswordInput
                        password={password}
                        onChange={this.onChange}
                        error={errors.password}
                      />
                      {errors.password && (
                        <ErrorNotice>{errors.password}</ErrorNotice>
                      )}
                    </Form.Field>
                    <Form.Field>
                      <ConnexionFormSubmit primary onClick={this.checkForm}>
                        Valider
                      </ConnexionFormSubmit>
                      {signInError && <ErrorNotice>{signInError}</ErrorNotice>}
                    </Form.Field>
                  </Form>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Screen>
    );
  }
}

Connexion.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  signInError: PropTypes.string
};

Connexion.defaultProps = {
  signInError: null
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  signInError: state.user.error
});

export default connect(mapStateToProps)(Connexion);
