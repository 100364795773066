import { createGlobalStyle } from "styled-components";

import UniversCondensed from "../fonts/Univers-Condensed.otf";
import UniversCondensedBold from "../fonts/Univers-Condensed-Bold.otf";
import Futura from "../fonts/Futura-LT-Book.otf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "UniversCondensed";
    src: url(${UniversCondensed});
  }

  @font-face {
    font-family: "UniversCondensedBold";
    src: url(${UniversCondensedBold});
  }

  @font-face {
    font-family: "Futura";
    src: url(${Futura});
  }

  * {
    box-sizing: border-box;
  }

  html {
    margin: 0;
    padding: 0;
    background: ${props => props.theme.colors.backgroundLight};
  }

  body {
    color: ${props => props.theme.colors.textBlack} !important;
    width: 100%;
    height: 102% !important;
    overflow: hidden;
    font-size: 14px;
    background: ${props => props.theme.colors.backgroundLight} !important;
  }

  tbody {
    display: block;
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  @media(max-width: 1366px) {
    body {
      font-size: 12px !important;
      line-height: 1.2857142857142857142857142857143em;
    }
    h1 {
      font-size: 1.5rem !important;
    }
  }
  @media only screen and (min-width: 1920px) {
    .ui.search.dropdown .menu {
        max-height: 31.37142857rem !important;
    }
  }

  .ui.table {
    font-size: 12px !important;
  }

  .ui.form .fields {
    margin: auto !important;
  }

  .ui.selection.dropdown {
    min-width: 12em !important;
  }
  .ui.multiple.search.dropdown>input.search {
    width: 1.5em !important;
  }
`;

export default GlobalStyle;
