import {
  USER_SIGN_IN_REQUEST,
  USER_SIGN_OUT_REQUEST
} from "../constants/ActionTypes";

export const signUserIn = (sLogin, sPassword) => ({
  type: USER_SIGN_IN_REQUEST,
  payload: { sLogin, sPassword }
});

export const signUserOut = () => ({
  type: USER_SIGN_OUT_REQUEST,
  payload: { token: null, error: null }
});
