import React, { Component } from "react";
import { Form, Select, Button, Grid, Label } from "semantic-ui-react";
import _, { uniqueId } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  getDossiers,
  getDossiersByProduct,
  getModulesByProduct,
  getVersionsByProduct,
  getProchaineVersions,
  setFilters,
} from "../../redux/actions/dossier";

import { getProduits } from "../../redux/actions/produit";

const typeOptions = [
  { key: "A", value: "A", text: "A" },
  { key: "M", value: "M", text: "M" },
  { key: "I", value: "I", text: "I" },
];

const PrintBtn = styled(Button)`
  height: 36px;
  margin-top: 23px !important;
  background: rgb(0, 151, 174) !important;
  color: white !important;
`;

const ContentVersion = styled.div`
  background: rgba(0, 0, 0, 0.05);
  padding: 5px;
  height: 134px;
  overflow: auto;
`;

const FilterColumn = styled(Grid.Column)`
  padding: 5px !important;
  width: 17.5%;
`;

const FirstFilterColumn = styled(Grid.Column)`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  width: 17.5%;
`;

const ModuleField = styled(Form.Field)`
  padding-left: 0;
  padding-top: 2vh;
`;

const RowFields = styled(Grid.Row)`
  display: flex;
  flex-direction: row;
`;

const StyledSelect = styled(Select)`
  .ui .selection .dropdown .menu > .item {
    padding: 5px !important;
  }

  & .item > .description {
    color: red !important;
    /* display: block;
    float: none !important; */
  }
`;

class FiltersVersion extends Component {
  state = {
    formData: {
      client: [],
      produit: 2,
      dossier: [],
      module: [],
      type: ["M", "A"],
    },
  };

  componentDidMount() {
    const { dispatch, history } = this.props;
    const { location } = history;
    const hash = location.hash
      ? JSON.parse(
          atob(location.hash.replace("#formData=", "").replace(/%22/g, '"'))
        )
      : null;
    if (hash) {
      this.setState({ formData: hash }, () => {
        dispatch(setFilters(hash));
        this.loadByProduct();
      });
    } else {
      this.loadByProduct();
    }
  }

  handleChange = (evt, data) => {
    const { dispatch, history, user } = this.props;
    const { formData } = this.state;
    this.setState(
      {
        formData: { ...formData, [data.id]: data.value },
      },
      () => {
        if (user.isAuthenticated) {
          this.state.formData.user = user.data;
        }
        dispatch(setFilters(this.state.formData));
        if (data.id === "client") {
          dispatch(getProduits(this.state.formData.client));
        }
        if (data.id === "produit") {
          this.setState(
            {
              formData: {
                ...formData,
                [data.id]: data.value,
                version: null,
                dossier: null,
                module: null,
              },
            },
            () => {
              dispatch(getModulesByProduct(this.state.formData.produit));
              dispatch(getVersionsByProduct(this.state.formData.produit));
              dispatch(
                getDossiersByProduct({
                  ...this.state.formData,
                  ...{ limit: false },
                })
              );
              dispatch(getDossiers(this.state.formData));
              history.push(
                `#formData=${btoa(JSON.stringify(this.state.formData))}`
              );
            }
          );
        }
        if (data.id !== "dossier") {
          dispatch(
            getDossiersByProduct({
              ...this.state.formData,
              ...{ limit: false },
            })
          );
        }
        dispatch(getDossiers(this.state.formData));
        if (this.state.formData.user) {
          delete this.state.formData.user;
        }
        history.push(`#formData=${btoa(JSON.stringify(this.state.formData))}`);
      }
    );
  };

  loadByProduct() {
    const { dispatch, user } = this.props;
    const { formData } = this.state;
    if (user.isAuthenticated) {
      formData.user = user.data;
    }
    dispatch(
      getDossiersByProduct({
        ...formData,
        ...{ limit: false },
      })
    );
    dispatch(getModulesByProduct(formData.produit));
    dispatch(getVersionsByProduct(formData.produit));
    dispatch(getDossiers(formData));
    dispatch(getProchaineVersions(user.data.iFKClient));
  }

  render() {
    const { formData } = this.state;
    const {
      tClient,
      tProduit,
      tDossier,
      tModule,
      tVersion,
      tProchaineVersion,
    } = this.props;

    const currentDate = DateTime.local();
    // suppression des doublons
    const cleanTdossier = _.uniqBy(tDossier, "iPKDossier");
    const cleantVersion = _.uniqBy(tVersion, (v) => {
      return [v.sVersion, v.sVersionPrevisionnelle].join();
    });

    return (
      <Form className="no-print">
        <Form.Group>
          <Grid style={{ margin: "0", width: "100%" }}>
            <Grid.Column width={10}>
              <RowFields stretched columns={5}>
                <FirstFilterColumn>
                  <Form.Field
                    id="client"
                    control={Select}
                    clearable
                    multiple
                    options={tClient.map((client) => {
                      return {
                        key: client.iPKClient,
                        value: client.iPKClient,
                        text: client.sNomAbrege,
                      };
                    })}
                    label={<label>Clients</label>}
                    placeholder="Sélection client"
                    onChange={this.handleChange}
                    onSearchChange={this.handleChange}
                    value={formData.client}
                    search
                    searchInput={{ id: "client" }}
                  />
                </FirstFilterColumn>
                <FilterColumn>
                  {" "}
                  <Form.Field
                    id="produit"
                    control={Select}
                    options={tProduit.map((produit) => {
                      return {
                        key: produit.iPKProduit,
                        value: produit.iPKProduit,
                        text: produit.sLibelle,
                      };
                    })}
                    label={<label>Produit</label>}
                    placeholder="Sélection produit"
                    onChange={this.handleChange}
                    onSearchChange={this.handleChange}
                    value={formData.produit}
                    search
                    searchInput={{ id: "produit" }}
                  />
                </FilterColumn>
                {formData.produit ? (
                  <FilterColumn>
                    <Form.Field
                      id="version"
                      control={Select}
                      clearable
                      options={cleantVersion.map((version) => {
                        return {
                          key: uniqueId,
                          value:
                            version.sVersionPrevisionnelle || version.sVersion,
                          text: version.sVersionPrevisionnelle ? (
                            <>
                              <i
                                className="hourglass half icon"
                                style={{ color: "blue" }}
                                id="rmenu"
                              ></i>
                              {version.sVersionPrevisionnelle}
                            </>
                          ) : (
                            version.sVersion
                          ),
                        };
                      })}
                      label={<label>Version</label>}
                      placeholder="Sélection version"
                      onChange={this.handleChange}
                      onSearchChange={this.handleChange}
                      value={formData.version}
                      search
                      searchInput={{ id: "version" }}
                    />
                  </FilterColumn>
                ) : null}
                <FilterColumn>
                  <Form.Field
                    id="type"
                    control={Select}
                    clearable
                    multiple
                    options={typeOptions}
                    value={formData.type}
                    label={<label>Types</label>}
                    placeholder="Type"
                    onChange={this.handleChange}
                    onSearchChange={this.handleChange}
                    search
                    searchInput={{ id: "type" }}
                  />
                </FilterColumn>

                <FilterColumn>
                  <Form.Field
                    id="dossier"
                    control={StyledSelect}
                    clearable
                    multiple
                    options={cleanTdossier.map((dossier) => {
                      return {
                        key: dossier.iPKDossier,
                        value: dossier.iPKDossier,
                        text: dossier.iPKDossier,
                      };
                    })}
                    label={<label>Dossiers</label>}
                    placeholder="Sélection dossier"
                    onChange={this.handleChange}
                    onSearchChange={this.handleChange}
                    value={formData.dossier}
                    search
                    searchInput={{ id: "dossier" }}
                  />
                </FilterColumn>
                <FilterColumn>
                  <PrintBtn
                    onClick={() => {
                      const oldTitle = document.title;
                      document.title = `dossiers_version_${currentDate.toFormat(
                        "ddLLyyyy"
                      )}`;
                      window.print();
                      document.title = oldTitle;
                    }}
                  >
                    Imprimer
                  </PrintBtn>
                </FilterColumn>
              </RowFields>
              {formData.produit && (
                <Grid.Row stretched>
                  <div style={{ maxWidth: "calc(33.33% - 5px)" }}>
                    <ModuleField
                      id="module"
                      control={Select}
                      clearable
                      multiple
                      options={tModule.map((item) => {
                        return {
                          key: item.iPKModule,
                          value: item.iPKModule,
                          text: item.sLibelle,
                        };
                      })}
                      label={<label>Modules</label>}
                      placeholder="Sélection module"
                      onChange={this.handleChange}
                      onSearchChange={this.handleChange}
                      value={formData.module}
                      search
                      searchInput={{ id: "module" }}
                    />
                  </div>
                </Grid.Row>
              )}
            </Grid.Column>
            <Grid.Column width={6}>
              <>
                <p style={{ marginBottom: 5 }}>
                  <b>Versions en cours de préparation autre qu'is@Dom</b>
                </p>
                <ContentVersion>
                  {tProchaineVersion.map((prochaineVersion) => {
                    return (
                      <Label
                        key={`${prochaineVersion.sProduit}-${prochaineVersion.sVersionPrevisionnelle}`}
                        style={{ marginBottom: 5 }}
                        color="yellow"
                        image
                      >
                        {prochaineVersion.sProduit}
                        <Label.Detail>
                          {prochaineVersion.sVersionPrevisionnelle}
                        </Label.Detail>
                      </Label>
                    );
                  })}
                </ContentVersion>
              </>
            </Grid.Column>
          </Grid>
        </Form.Group>
      </Form>
    );
  }
}

FiltersVersion.defaultProps = {
  tClient: [],
  tDossier: [],
  tModule: [],
  tProduit: [],
  tVersion: [],
  tProchaineVersion: [],
};

FiltersVersion.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  tClient: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  tDossier: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  tProduit: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  tModule: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  tVersion: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  tProchaineVersion: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const mapStateToProps = (state) => ({
  tClient: state.client.tClient,
  tDossier: state.dossier.tOptionDossier,
  tProduit: state.produit.tProduit,
  tModule: state.dossier.tOptionModule,
  tVersion: state.dossier.tOptionVersion,
  tProchaineVersion: state.dossier.tProchaineVersion,
  user: state.user,
});

export default connect(mapStateToProps)(FiltersVersion);
