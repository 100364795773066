import { call, put } from "redux-saga/effects";
import { gql } from "apollo-boost";
import { fetchApollo } from "../../graphql/apolloClient";

import {
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE
} from "../constants/ActionTypes";

export function tmp() {}

export function* getClients() {
  try {
    const GET_CLIENTS = gql`
      {
        getClients {
          iPKClient
          sNomAbrege
        }
      }
    `;
    const response = yield call(fetchApollo, GET_CLIENTS);
    const clients = response.data.getClients;

    if (clients !== null) {
      yield put({
        type: GET_CLIENTS_SUCCESS,
        payload: { tClient: clients }
      });
    } else {
      yield put({
        type: GET_CLIENTS_FAILURE,
        payload: "Erreur de chargement des clients"
      });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Page not found";
        break;
      case 401:
        message = "Erreur de chargement des clients";
        break;
      default:
        message = "Erreur de chargement des clients";
        break;
    }

    yield put({ type: GET_CLIENTS_FAILURE, payload: message });
  } finally {
    /*yield put({
        type: LOADING_DEACTIVATE_SUCCESS,
        payload: { target: "config" }
      });*/
  }
}
