import {
  GET_DOSSIERS_REQUEST,
  ADD_DOSSIERS_REQUEST,
  GET_DOSSIERS_BY_PRODUCT_REQUEST,
  GET_MODULES_BY_PRODUCT_REQUEST,
  GET_VERSIONS_BY_PRODUCT_REQUEST,
  GET_PROCHAINE_VERSIONS_REQUEST,
  SET_FILTERS
} from "../constants/ActionTypes";

export const getDossiers = params => ({
  type: GET_DOSSIERS_REQUEST,
  payload: { params }
});

export const getDossiersByProduct = params => ({
  type: GET_DOSSIERS_BY_PRODUCT_REQUEST,
  payload: { params }
});

export const getModulesByProduct = params => ({
  type: GET_MODULES_BY_PRODUCT_REQUEST,
  payload: { params }
});

export const getVersionsByProduct = params => ({
  type: GET_VERSIONS_BY_PRODUCT_REQUEST,
  payload: { params }
});

export const getProchaineVersions = iFKClient => ({
  type: GET_PROCHAINE_VERSIONS_REQUEST,
  payload: { iFKClient }
});

export const addDossiers = params => ({
  type: ADD_DOSSIERS_REQUEST,
  payload: { params }
});

export const setFilters = params => ({
  type: SET_FILTERS,
  payload: { params }
});
