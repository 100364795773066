import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Route,
  Switch,
  Redirect,
  matchPath,
  withRouter
} from "react-router-dom";
import { TransitionGroup, Transition } from "react-transition-group";

import {
  handleScreenEnterAnimation,
  handleScreenExitAnimation
} from "../ui/animations";

import NotFound from "./screens/Erreur404";

import routes from "./screens";

const filterRoutes = location =>
  routes.filter(
    ({ path, privateRoute, exact, transitionAnimation }) =>
      !!matchPath(location.pathname, {
        path,
        privateRoute,
        exact,
        transitionAnimation
      })
  );

const AnimatedRoutes = ({ isAuthenticated, animatedTransitions, location }) => {
  let anims = {
    enter: handleScreenEnterAnimation,
    exit: handleScreenExitAnimation,
    entering: () => {},
    entered: () => {},
    exiting: () => {},
    exited: () => {},
    delay: 1000
  };
  const selected = filterRoutes(location);
  return (
    <Switch>
      <Route
        render={props => {
          if (selected.length === 0) {
            return (
              <TransitionGroup appear exit>
                <Transition
                  key="404"
                  timeout={anims.delay}
                  onEnter={anims.enter}
                  onEntering={anims.entering}
                  onEntered={anims.entered}
                  onExit={anims.exit}
                  onExiting={anims.exiting}
                  onExited={anims.exited}
                >
                  <NotFound />
                </Transition>
              </TransitionGroup>
            );
          }

          if (selected[0].privateRoute === true && !isAuthenticated) {
            return <Redirect to={`/connexion${window.location.hash}`} />;
          }

          if (
            animatedTransitions === false ||
            selected[0].transitionAnimation === false
          ) {
            anims = { enter: () => {}, exit: () => {}, delay: 0 };
          }

          if (animatedTransitions === true) {
            return (
              <TransitionGroup appear exit>
                {selected.map(({ key, ...attribs }) => (
                  <Transition
                    key={`child-${key}`}
                    timeout={anims.delay}
                    onEnter={anims.enter}
                    onEntering={anims.entering}
                    onEntered={anims.entered}
                    onExit={anims.exit}
                    onExiting={anims.exiting}
                    onExited={anims.exited}
                  >
                    <Suspense fallback={<div>Chargement...</div>}>
                      {React.createElement(
                        selected[0].component,
                        { ...props, ...attribs, location },
                        null
                      )}
                    </Suspense>
                  </Transition>
                ))}
              </TransitionGroup>
            );
          }

          return (
            <Suspense fallback={<div>Chargement...</div>}>
              {React.createElement(selected[0].component, { location }, null)}
            </Suspense>
          );
        }}
      />
    </Switch>
  );
};

AnimatedRoutes.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  animatedTransitions: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired
};

AnimatedRoutes.defaultProps = {
  animatedTransitions: true
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated
});

export default withRouter(connect(mapStateToProps)(AnimatedRoutes));
